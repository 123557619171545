/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from "../actions/types/types";
////////////////////////////////////////////////////////////////////WEBSITES DEFAULT STATE
const websitesDefaultState = [];
//////////////////////////////////////////////////////////////////////////WEBSITES REDUCER
const websites = (state = websitesDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_WEBSITES_SUCCESS:
      return Array.isArray(action.websites) ? [...action.websites] : [action.websites];
    case types.GET_WEBSITES_FAILURE:
      return [...state];

    // POST request
    case types.POST_WEBSITE_SUCCESS:
      return [...state, action.website];
    case types.POST_WEBSITE_FAILURE:
      return [...state];

    // PUT request
    case types.PUT_WEBSITE_SUCCESS:
      return state.map((website) => {
        if (website._id === action.website._id) {
          return { ...website, ...action.website };
        } else {
          return website;
        }
      });
    case types.PUT_WEBSITE_FAILURE:
      return [...state];

    // DELETE request
    case types.DELETE_WEBSITE_SUCCESS:
      return state.filter((website) => website._id !== action.website._id);
    case types.DELETE_WEBSITE_FAILURE:
      return [...state];

    default:
      return state;
  }
};

export default websites;
