////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  Col,
  CustomButton,
  CustomInput,
  CustomInputGroupText,
  Form,
  FormFeedback,
  FormGroup,
  InputGroup,
  Label,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "@ibiliaze/reactstrap";
import { FullPage, Section } from "@ibiliaze/react-base";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import {} from "../../actions/reviews";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { calculateVal } from "../../utils/property";
//////////////////////////////////////////////////////////////////////////////////////////

const PropertyPage = ({}) => {
  // Search Params
  const [searchParams, setSearchParams] = useSearchParams();

  // State
  const [inputs, setInputs] = useState({
    propName: searchParams.get("propName") || "",
    price: searchParams.get("price") || 0,
    unitPrice: searchParams.get("unitPrice") || 0,
    cap: searchParams.get("cap") || 0,
    rent: searchParams.get("rent") || 0,
    deposit: searchParams.get("deposit") || 0,
    rooms: searchParams.get("rooms") || 0,
    units: searchParams.get("units") || 0,
    vacancy: searchParams.get("vacancy") || 0,
    down: searchParams.get("down") || 0,
    years: searchParams.get("years") || 0,
    debt: searchParams.get("debt") || 0,
    expenses: searchParams.get("expenses") || 0,
    dcr: searchParams.get("dcr") || 0,
    value: searchParams.get("value") || 0,
    noi: searchParams.get("noi") || 0,
    roi: searchParams.get("roi") || 0,
  });
  const [reviewsWritten, setReviewsWritten] = useState([]);
  const [edit, setEdit] = useState({ review: false });

  // onChange functions
  const onInputsChange = (e) => {
    setInputs((c) => calculateVal({ ...c, [e.target.name]: e.target.value }));
    setSearchParams({ ...inputs, [e.target.name]: e.target.value });
  };

  // onClick functions
  const copyUrl = (_) => {
    try {
      navigator.clipboard.writeText(window.location.href).then(() => {});
    } catch (e) {
      console.log("Failed to copy to clipboard");
    }
  };

  const shareUrl = (_) => {
    const url = encodeURIComponent(window.location.href.replace(" ", "+"));
    window.open(`https://wa.me/?text=This is my property on Afenia.com%0A%0A${url}`);
  };

  // Lifecycle hooks
  useEffect(() => {
    setInputs((c) => calculateVal({ ...c, ...searchParams }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // JSX
  return (
    <FullPage>
      <Section className="below-header custom-page p-t-m">
        <h1 className="t-align-c">Estimate</h1>
        <Form>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label>Property Name</Label>
                <CustomInput
                  type="text"
                  name="propName"
                  value={inputs.propName}
                  onChange={onInputsChange}
                  placeholder="My Property"
                />
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label>Price</Label>
                <InputGroup>
                  <CustomInputGroupText>£</CustomInputGroupText>
                  <CustomInput type="number" name="price" value={inputs.price} onChange={onInputsChange} />
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label>Cap rate</Label>
                <InputGroup>
                  <CustomInput type="number" name="cap" value={inputs.cap} onChange={onInputsChange} />
                  <CustomInputGroupText>%</CustomInputGroupText>
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label>Deposit</Label>
                <InputGroup>
                  <CustomInput
                    type="number"
                    name="deposit"
                    value={inputs.deposit}
                    onChange={onInputsChange}
                  ></CustomInput>
                  <CustomInputGroupText>%</CustomInputGroupText>
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label>Years</Label>
                <CustomInput
                  type="number"
                  name="years"
                  value={inputs.years}
                  onChange={onInputsChange}
                ></CustomInput>
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label>Rent price</Label>
                <InputGroup>
                  <CustomInputGroupText>£</CustomInputGroupText>
                  <CustomInput
                    type="number"
                    name="rent"
                    value={inputs.rent}
                    onChange={onInputsChange}
                  ></CustomInput>
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label>Bedrooms</Label>
                <CustomInput
                  type="number"
                  name="rooms"
                  value={inputs.rooms}
                  onChange={onInputsChange}
                ></CustomInput>
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label>Units</Label>
                <CustomInput
                  type="number"
                  name="units"
                  value={inputs.units}
                  onChange={onInputsChange}
                ></CustomInput>
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup>
                <Label>Vacancy</Label>
                <InputGroup>
                  <CustomInput
                    type="number"
                    name="vacancy"
                    value={inputs.vacancy}
                    onChange={onInputsChange}
                  ></CustomInput>
                  <CustomInputGroupText>%</CustomInputGroupText>
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Debt</Label>
                <InputGroup>
                  <CustomInputGroupText>£</CustomInputGroupText>
                  <CustomInput
                    disabled
                    type="number"
                    name="debt"
                    value={inputs.debt}
                    onChange={onInputsChange}
                  ></CustomInput>
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Down payment</Label>
                <InputGroup>
                  <CustomInputGroupText>£</CustomInputGroupText>
                  <CustomInput
                    disabled
                    type="number"
                    name="down"
                    value={inputs.down}
                    onChange={onInputsChange}
                  ></CustomInput>
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Expenses (inc. Tax)</Label>
                <InputGroup>
                  <CustomInputGroupText>£</CustomInputGroupText>
                  <CustomInput
                    disabled
                    type="number"
                    name="expenses"
                    value={inputs.expenses}
                    onChange={onInputsChange}
                  ></CustomInput>
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Debt coverage ratio (DCR)</Label>
                <CustomInput
                  type="number"
                  invalid={inputs.price !== 0 && inputs.dcr < 1.25 ? true : false}
                  valid={inputs.price !== 0 && inputs.dcr > 1.25 ? true : false}
                  name="dcr"
                  value={inputs.dcr}
                  disabled
                ></CustomInput>
                <FormFeedback>DCR is ideal above 1.25</FormFeedback>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Net operating income (NOI)</Label>
                <InputGroup>
                  <CustomInputGroupText>£</CustomInputGroupText>
                  <CustomInput type="number" name="noi" value={inputs.noi} disabled></CustomInput>
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Return on investment (ROI)</Label>
                <InputGroup>
                  <CustomInput
                    type="number"
                    invalid={inputs.price !== 0 && inputs.roi < 8 ? true : false}
                    valid={inputs.price !== 0 && inputs.roi > 8 ? true : false}
                    name="roi"
                    value={inputs.roi}
                    disabled
                  ></CustomInput>
                  <CustomInputGroupText>%</CustomInputGroupText>
                  <FormFeedback>ROI is ideal above 8%</FormFeedback>
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Price per unit</Label>
                <InputGroup>
                  <CustomInputGroupText>£</CustomInputGroupText>
                  <CustomInput
                    disabled
                    type="number"
                    name="unitPrice"
                    value={inputs.unitPrice}
                    onChange={onInputsChange}
                  ></CustomInput>
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Value</Label>
                <InputGroup>
                  <CustomInputGroupText>£</CustomInputGroupText>
                  <CustomInput type="value" name="dcr" value={inputs.value} disabled />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <br />
        </Form>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <CustomButton onClick={copyUrl} color="primary" id="copy-button">
            Copy Share Link
          </CustomButton>
          <UncontrolledPopover placement="top" trigger="legacy" target="copy-button">
            <PopoverBody>Copied</PopoverBody>
          </UncontrolledPopover>

          <CustomButton color="success" onClick={shareUrl}>
            Share on WhatsApp
          </CustomButton>
        </div>

        <br />
      </Section>
    </FullPage>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(PropertyPage);
