////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Row } from "@ibiliaze/reactstrap"; // Has to be at the top for css precedence
import { Fluency, FullPage, Section } from "@ibiliaze/react-base";
import { Sidebar } from "@ibiliaze/react-sidebar";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { putUser } from "../../actions/user";
/////////////////////////////////////////////////////////////////////////////////////HOOKS
import useWindowSize from "../../hooks/useWindowSize";
/////////////////////////////////////////////////////////////////////////////////////UTILS
////////////////////////////////////////////////////////////////////////////////COMPONENTS
//////////////////////////////////////////////////////////////////////////////////////////

const HomePage = ({ user, putUser }) => {
  // State
  const [section, setSection] = useState("Incomes");

  // Hooks
  const windowSize = useWindowSize();

  // Navigate
  const navigate = useNavigate();

  // Lifecycle hooks
  useEffect(() => {
    try {
      if (!user?.isAuthenticated) {
        navigate("/");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    } catch (e) {
      console.error(e);
    }
  }, []);

  // JSX
  return (
    <FullPage>
      <Row /> {/* Let this stay here */}
      <Section className="below-header custom-page p-t-m">
        <Sidebar
          section={section}
          setSection={setSection}
          mobileWidth={580}
          windowWidth={windowSize.width}
          sections={[
            {
              title: (
                <>
                  <Fluency name="positive-dynamic" size={40} /> Income
                </>
              ),
              id: "Income",
            },
            {
              title: (
                <>
                  <Fluency name="down" size={40} /> Debt
                </>
              ),
              id: "Debt",
            },
            {
              title: (
                <>
                  <Fluency name="github-logo" size={40} /> Git
                </>
              ),
              id: "Git",
            },
            {
              title: (
                <>
                  <Fluency name="thinking-bubble" size={40} /> Reviews
                </>
              ),
              id: "Reviews",
            },
            {
              title: (
                <>
                  <Fluency name="website" size={40} /> Websites
                </>
              ),
              id: "Websites",
            },
            {
              title: (
                <>
                  <Fluency name="home" size={40} /> Property
                </>
              ),
              id: "Property",
            },
            {
              title: (
                <>
                  <Fluency name="google-logo" size={40} /> Google
                </>
              ),
              id: "Google",
            },
          ]}
        />
        {section === "Income" ? (
          <>{navigate("/income")}</>
        ) : section === "Debt" ? (
          <>{navigate("/debt")}</>
        ) : section === "Git" ? (
          <>{navigate("/git")}</>
        ) : section === "Reviews" ? (
          <>{navigate("/reviews")}</>
        ) : section === "Property" ? (
          <>{navigate("/property")}</>
        ) : section === "Google" ? (
          <>{navigate("/google")}</>
        ) : section === "Websites" ? (
          <>{navigate("/websites")}</>
        ) : (
          <></>
        )}
      </Section>
    </FullPage>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, { putUser })(HomePage);
