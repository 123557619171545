////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { useState } from "react";
import { connect } from "react-redux";
import { Col, CustomButton, CustomInput, FormGroup, InputGroup, Row } from "@ibiliaze/reactstrap";
import { FullPage, Section } from "@ibiliaze/react-base";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import {
  triggerNpmUpdate,
  pipelineHousekeeping,
  deployKeyHousekeeping,
  webhookHousekeeping,
  triggerWebhook,
} from "../../actions/gitlab";
//////////////////////////////////////////////////////////////////////////////////////////

const GitlabPage = ({
  triggerNpmUpdate,
  pipelineHousekeeping,
  deployKeyHousekeeping,
  webhookHousekeeping,
  triggerWebhook,
}) => {
  // State
  const [inputs, setInputs] = useState({ package: "", projectName: "" });

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onPipelineNpmSyncClick = async () => {
    try {
      await triggerNpmUpdate({ pipeline: "sync", callback: async (data) => {} });
    } catch (e) {
      console.error(e);
    }
  };

  const onPipelineNpmSyncUpdate = async () => {
    try {
      await triggerNpmUpdate({ pipeline: "update", package: inputs.package, callback: async (data) => {} });
    } catch (e) {
      console.error(e);
    }
  };

  const onDeleteOldPipelineslick = async () => {
    try {
      await pipelineHousekeeping({ callback: async (data) => {} });
    } catch (e) {
      console.error(e);
    }
  };

  const onDeleteOldDeployKeyslick = async () => {
    try {
      await deployKeyHousekeeping({ callback: async (data) => {} });
    } catch (e) {
      console.error(e);
    }
  };

  const onDeleteOldWebhookslick = async () => {
    try {
      await webhookHousekeeping({ callback: async (data) => {} });
    } catch (e) {
      console.error(e);
    }
  };

  const onTriggerWebhookClick = async () => {
    try {
      await triggerWebhook({ projectName: inputs.projectName, callback: async (data) => {} });
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <FullPage>
      <Section className="below-header custom-page p-t-m">
        <Row>
          <Col md={6}>
            <h2>NPM Update</h2>
            <FormGroup>
              <CustomButton color="primary" onClick={onPipelineNpmSyncClick}>
                Trigger Sync
              </CustomButton>
            </FormGroup>

            <FormGroup>
              <InputGroup>
                <CustomInput
                  name="package"
                  value={inputs.package}
                  onChange={onInputsChange}
                  placeholder="Package"
                />
                <CustomButton color="primary" onClick={onPipelineNpmSyncUpdate}>
                  Trigger Update
                </CustomButton>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={6}>
            <h2>Webhooks</h2>
            <FormGroup>
              <InputGroup>
                <CustomInput
                  name="projectName"
                  value={inputs.projectName}
                  onChange={onInputsChange}
                  placeholder="Project"
                />
                <CustomButton color="primary" onClick={onTriggerWebhookClick}>
                  Trigger Webhook
                </CustomButton>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={6}>
            <h2>Housekeeping</h2>
            <FormGroup>
              <CustomButton color="primary" onClick={onDeleteOldPipelineslick}>
                Delete Old Pipelines
              </CustomButton>
            </FormGroup>

            <FormGroup>
              <CustomButton color="primary" onClick={onDeleteOldDeployKeyslick}>
                Delete Old Deploy Keys
              </CustomButton>
            </FormGroup>

            <FormGroup>
              <CustomButton color="primary" onClick={onDeleteOldWebhookslick}>
                Delete Old Webhooks
              </CustomButton>
            </FormGroup>
          </Col>
        </Row>
      </Section>
    </FullPage>
  );
};

export default connect(null, {
  triggerNpmUpdate,
  pipelineHousekeeping,
  deployKeyHousekeeping,
  webhookHousekeeping,
  triggerWebhook,
})(GitlabPage);
