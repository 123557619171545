///////////////////////////////////////////////////////////////////////////////////MODULES
import { Api } from "@ibiliaze/react-base";
import errorParser from "@ibiliaze/http-error-parser";
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from "./types/types";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from "./alert";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from "../utils/resolveEnv";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/website
export const getWebsitesSuccess = (websites) => ({ type: types.GET_WEBSITES_SUCCESS, websites });
export const getWebsitesFailure = (errorMessage) => ({ type: types.GET_WEBSITES_FAILURE, errorMessage });
export const getWebsites = (endpoint) => async (dispatch) => {
  try {
    const res = await api.get(
      !endpoint ? `${resolveHost()}/api/website` : `${resolveHost()}/api/website${endpoint}`
    );

    dispatch(getWebsitesSuccess(res.data));
  } catch (e) {
    dispatch(getWebsitesFailure(e.message));
  }
};

// POST /api/website
export const postWebsiteSuccess = (website) => ({ type: types.POST_WEBSITE_SUCCESS, website });
export const postWebsiteFailure = (errorMessage) => ({ type: types.POST_WEBSITE_FAILURE, errorMessage });
export const postWebsite = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Saving the website...", false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/website`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.POST_WEBSITE_SUCCESS));
    dispatch(postWebsiteSuccess(res.data.website));
  } catch (e) {
    dispatch(
      setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.POST_WEBSITE_FAILURE)
    );
    dispatch(postWebsiteFailure(e.message));
  }
};

// PUT /api/website
export const putWebsiteSuccess = (website) => ({ type: types.PUT_WEBSITE_SUCCESS, website });
export const putWebsiteFailure = (errorMessage) => ({ type: types.PUT_WEBSITE_FAILURE, errorMessage });
export const putWebsite = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Updating the website...", false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/website/${data.websiteId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_WEBSITE_SUCCESS));
    dispatch(putWebsiteSuccess(res.data.website));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_WEBSITE_FAILURE));
    dispatch(putWebsiteFailure(e.message));
  }
};

// DELETE /api/website
export const deleteWebsiteSuccess = (website) => ({ type: types.DELETE_WEBSITE_SUCCESS, website });
export const deleteWebsiteFailure = (errorMessage) => ({ type: types.DELETE_WEBSITE_FAILURE, errorMessage });
export const deleteWebsite = (websiteId) => async (dispatch) => {
  try {
    dispatch(setAlert("Deleting the website...", false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/website/${websiteId}`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_WEBSITE_SUCCESS));
    dispatch(deleteWebsiteSuccess(res.data.website));
  } catch (e) {
    dispatch(
      setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.DELETE_WEBSITE_FAILURE)
    );
    dispatch(deleteWebsiteFailure(e.message));
  }
};

// GET /api/website/statuses
export const getWebsiteStatuses = (data) => async (dispatch) => {
  try {
    dispatch(setAlert("Fetching websites statuses...", false, null, null, true));
    const res = await api.get(
      !data?.endpoint
        ? `${resolveHost()}/api/website/statuses`
        : `${resolveHost()}/api/website/statuses${data?.endpoint}`
    );

    await data?.callback(res.data);

    dispatch(getWebsitesSuccess(res.data.websites));
    dispatch(setAlert(res.data.message, false, res.status, null, false));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, null, false));
  }
};
