////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Badge,
  Col,
  CustomButton,
  CustomInput,
  CustomListGroup,
  CustomListGroupItem,
  Form,
  FormGroup,
  InputGroup,
  Label,
  ListGroupItemText,
  Row,
} from "@ibiliaze/reactstrap";
import { FullPage, Section } from "@ibiliaze/react-base";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import {
  getWebsites,
  postWebsite,
  putWebsite,
  deleteWebsite,
  getWebsiteStatuses,
} from "../../actions/websites";
//////////////////////////////////////////////////////////////////////////////////////////

const inputsDefault = {
  url: "",
  route: "",
  type: "",
  stack: "",
  gitRepo: "",
  tenant: "",
  up: false,
};

const WebsitesPage = ({
  websites,
  getWebsites,
  postWebsite,
  putWebsite,
  deleteWebsite,
  getWebsiteStatuses,
}) => {
  // State
  const [inputs, setInputs] = useState({ ...inputsDefault });
  const [edit, setEdit] = useState({ website: false });

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));

  // Lifecycle hooks
  useEffect(() => {
    try {
      (async () => await getWebsites())();
    } catch (e) {
      console.error(e);
    }
  }, []);

  // onClick functions
  const onGetStatusClick = async () => {
    try {
      await getWebsiteStatuses();
    } catch (e) {
      console.error(e);
    }
  };

  const onWebsiteClick = async (website) => {
    try {
      setEdit((c) => ({ ...c, website: true }));
      setInputs((c) => ({ ...inputsDefault, ...website }));
    } catch (e) {
      setEdit((c) => ({ ...c, website: false }));
      console.error(e);
    }
  };

  const onDeleteWebsiteClick = async (e) => {
    try {
      await deleteWebsite(inputs._id);
      setEdit((c) => ({ ...c, website: false }));
      setInputs({ ...inputsDefault });
    } catch (e) {
      setEdit((c) => ({ ...c, website: false }));
      console.error(e);
    }
  };

  // onSubmit function
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const req = edit.website ? putWebsite : postWebsite;
      await req({ websiteId: inputs._id, ...inputs });

      setEdit((c) => ({ ...c, website: false }));
      setInputs({ ...inputsDefault });
    } catch (e) {
      console.error(e);
      setEdit((c) => ({ ...c, website: false }));
      setInputs({ ...inputsDefault });
    }
  };

  // JSX
  return (
    <FullPage>
      <Section className="below-header custom-page p-t-m">
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md={6}>
              <h2>Websites</h2>

              <FormGroup>
                <CustomButton color="primary" black onClick={onGetStatusClick}>
                  Get Statuses
                </CustomButton>
              </FormGroup>

              <CustomListGroup>
                {websites?.map((website, i) => (
                  <CustomListGroupItem
                    key={i}
                    onClick={(_) => onWebsiteClick(website)}
                    green={website?.up ? true : false}
                    red={!website?.up ? true : false}
                  >
                    <ListGroupItemText>
                      {website?.up ? <Badge color="success">UP</Badge> : <Badge color="danger">DOWN</Badge>}{" "}
                      {website?.url}{" "}
                      <div>
                        <Badge>{website?.type}</Badge> <Badge>{website?.stack}</Badge>
                      </div>
                    </ListGroupItemText>
                  </CustomListGroupItem>
                ))}
              </CustomListGroup>
            </Col>
            <Col md={6}>
              <br />
              <div className="stick-to-top">
                <FormGroup>
                  <Label>URL *</Label>
                  <InputGroup>
                    <CustomInput
                      required
                      placeholder="URL *"
                      name="url"
                      value={inputs.url}
                      onChange={onInputsChange}
                    />
                    <CustomButton
                      color="primary"
                      black
                      onClick={(_) =>
                        window.open(
                          !inputs?.url?.includes("https://")
                            ? `https://${inputs?.url}${inputs?.route || ""}`
                            : `${inputs?.url}${inputs?.route || ""}`
                        )
                      }
                    >
                      Hit
                    </CustomButton>
                  </InputGroup>
                </FormGroup>

                <FormGroup floating>
                  <CustomInput
                    required
                    placeholder="Route"
                    name="route"
                    value={inputs.route}
                    onChange={onInputsChange}
                  />
                  <Label>Route</Label>
                </FormGroup>

                <FormGroup floating>
                  <CustomInput
                    required
                    placeholder="Type *"
                    name="type"
                    type="select"
                    value={inputs.type}
                    onChange={onInputsChange}
                  >
                    <option value="">Select</option>
                    <option value="fullstack">Full Stack</option>
                    <option value="frontend">Frontend</option>
                    <option value="backend">Backend</option>
                  </CustomInput>
                  <Label>Type *</Label>
                </FormGroup>

                <FormGroup floating>
                  <CustomInput
                    required
                    placeholder="Stack *"
                    name="stack"
                    type="select"
                    value={inputs.stack}
                    onChange={onInputsChange}
                  >
                    <option value="">Select</option>
                    <option value="mern">MERN</option>
                    <option value="react">React</option>
                    <option value="python">Python</option>
                  </CustomInput>
                  <Label>Stack *</Label>
                </FormGroup>

                <FormGroup floating>
                  <CustomInput
                    required
                    placeholder="Git Repo *"
                    name="gitRepo"
                    value={inputs.gitRepo}
                    onChange={onInputsChange}
                  />
                  <Label>Git Repo *</Label>
                </FormGroup>

                <FormGroup floating>
                  <CustomInput
                    placeholder="Tenant *"
                    name="tenant"
                    type="select"
                    value={inputs.tenant}
                    onChange={onInputsChange}
                  >
                    <option value="">None</option>
                    <option value="tenant-a">tenant-a</option>
                    <option value="netlify">Netlify</option>
                  </CustomInput>
                  <Label>Tenant *</Label>
                </FormGroup>

                <FormGroup>
                  <CustomButton type="submit" color="primary" black>
                    Save
                  </CustomButton>
                  {edit.website && (
                    <>
                      <CustomButton color="danger" onClick={onDeleteWebsiteClick}>
                        Delete
                      </CustomButton>
                    </>
                  )}
                </FormGroup>
              </div>
            </Col>
          </Row>
        </Form>
        <br />
      </Section>
    </FullPage>
  );
};

const mapStateToProps = (state) => ({ websites: state.websites });

export default connect(mapStateToProps, {
  getWebsites,
  postWebsite,
  putWebsite,
  deleteWebsite,
  getWebsiteStatuses,
})(WebsitesPage);
