import { divide, multiply, subtract } from "mathjs";

export const calculateVal = ({
  propName,
  price,
  unitPrice,
  cap,
  rent,
  deposit,
  rooms,
  units,
  vacancy,
  down,
  years,
  debt,
  expenses,
  dcr,
  value,
  noi,
  roi,
}) => {
  const calculatedUnitPrice = divide(price, units);
  //
  const occupancy = divide(subtract(100, vacancy), 100);
  const grossIncome = multiply(units, rent, 12, occupancy);
  //
  const calculatedExpenses = multiply(grossIncome, 0.4);
  const calculatedNoi = grossIncome - calculatedExpenses;
  //
  const calculatedDown = multiply(price, divide(deposit, 100));
  const calculatedDebt = multiply(price - calculatedDown, 0.0625);
  const calculatedDcr = divide(calculatedNoi, calculatedDebt);
  //
  const calculatedValue = divide(calculatedNoi, divide(cap, 100));
  //
  const calculatedRoi = multiply(divide(calculatedNoi - calculatedDebt, calculatedDown), 100);
  const calculation = {
    propName,
    price,
    unitPrice: calculatedUnitPrice,
    cap,
    rent,
    down: calculatedDown,
    rooms,
    units,
    vacancy,
    deposit,
    years,
    debt: calculatedDebt,
    expenses: calculatedExpenses,
    dcr: calculatedDcr,
    value: calculatedValue,
    noi: calculatedNoi,
    roi: calculatedRoi,
  };
  return calculation;
};
